import React from "react"
import { useEffect, useState } from "react"
import { HiChevronDoubleUp } from "react-icons/hi"
//import IconButton from "../../images/IconButton"
import tw, { styled } from "twin.macro"
import feather from "../misc/feather.jsx"

const PAGE_Y_OFFSET = 500
const Container = tw.div`fixed bottom-0 right-0 z-10`
const Button = tw.button`rounded-full m-8 p-2 bg-gray-300`

const ScrollToTop = () => {
  const [show, setShow] = useState(false)

  const changeShow = () => {
    if (window.pageYOffset > PAGE_Y_OFFSET) {
      setShow(true)
    } else {
      setShow(false)
    }
  }

  const onScrollTop = () => {
    window.scroll({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    window.addEventListener("scroll", changeShow)
    return () => window.removeEventListener("scroll", changeShow)
  }, [])

  if (show)
    return (
      <Container>
        <Button onClick={onScrollTop}>
          {feather("chevrons-up", ["40", "40"])}
        </Button>
      </Container>
    )
  else return null
}

export default ScrollToTop
