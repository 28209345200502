import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import tw, { styled } from "twin.macro"
import { Link as Scroll } from "react-scroll"

import useAnimatedNavToggler from "./helpers/useAnimatedNavToggler.jsx"
import logo from "../images/RossoLogo.png"
import feather from "./misc/feather.jsx"

const Header = tw.header`
  flex justify-between items-center
  mx-auto py-4 sticky top-0 z-10 bg-gray-100 -mx-8 -mt-8
`

export const NavLinks = tw.div`inline-block`

export const NavLink = tw(Link)`
  text-sm my-2  mx-4  2xl:mx-6 md:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`
export const NavScroll = tw(Scroll)`
  text-sm my-2  mx-4  2xl:mx-6 md:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500 cursor-pointer
`

export const Tel = tw.a`
  text-sm my-2  mx-4 2xl:mx-6 md:my-0 inline-block
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-20`}
  }
`

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between mx-4`
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`)

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center px-4
`

export default ({
  logoLink,
  links,
  className,
  collapseBreakpointClass = "md",
}) => {
  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink to="/">サクッとシリーズTOP</NavLink>
      <NavScroll to="news" smooth={true} offset={-100}>
        News
      </NavScroll>
      <NavScroll to="feature" smooth={true} offset={-100}>
        サクッとクラウドの特徴
      </NavScroll>
      <NavScroll to="usecase" smooth={true} offset={-100}>
        事例紹介
      </NavScroll>
      <NavLink
        to="https://www.rosso-tokyo.co.jp/information/"
        target="_blank"
        rel="noopener noreferrer"
      >
        会社概要
      </NavLink>
      <NavLink to="/contact/">お問い合わせ</NavLink>
      <Tel href="tel:0364279037">☎︎ 03-6427-9037</Tel>
    </NavLinks>,
  ]

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler()
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass]

  const defaultLogoLink = (
    <LogoLink to="/">
      <img src={logo} alt="logo" />
    </LogoLink>
  )

  logoLink = logoLink || defaultLogoLink
  links = links || defaultLinks

  return (
    <Header>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {links}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks
            ? feather("x", ["30", "30"])
            : feather("menu", ["30", "30"])}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  )
}

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
}
