import React from "react"
import tw, { styled } from "twin.macro"
import { Link } from "gatsby"
import Header, { NavLink, NavLinks, Tel, LogoLink } from "./Header.jsx"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "../images/hero-cloud.svg"
const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`

const Container = tw.div`relative -mx-8 -mt-8`
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100`

const LeftColumn = styled.div`
  ${tw`p-5 lg:py-10 lg:pl-6 2xl:pl-10 lg:pr-3 w-full lg:w-6/12 bg-center bg-cover lg:bg-none`}
  background-image: url(${BackgroundImage});
`

const RightColumn = tw.div`h-auto w-full lg:w-6/12 hidden lg:inline-block`

const Content = tw.div`mt-24 flex flex-col sm:items-center lg:items-stretch`
const Heading = tw.h1`text-center lg:text-left text-4xl lg:text-5xl xl:text-6xl font-black leading-none mt-12 mb-12 lg:mt-0 lg:mb-0`
const Paragraph = tw.p`max-w-lg my-8 text-center lg:text-left text-sm lg:text-base  leading-loose`

const ActionsContainer = tw.div`flex flex-col lg:flex-row `
const Actions = tw(
  Link
)`mb-8  mx-2 lg:mb-0 text-center inline-block w-full sm:w-72 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300 bg-primary-500 text-gray-100 hover:bg-primary-700`

export default ({
  heading = (
    <>
      ｢サクッとクラウド｣で
      <br />
      <br />
      DX推進をスマートに
    </>
  ),
  description = (
    <>
      サーバレス、自動化、コスト削減など、お客様のニーズに合わせたプランをスピーディーにご提案。
      <br />
      AWS認証取得実績多数のRossoが誇る、クラウドスペシャリストがDX推進の第一歩をサポートいたします。
    </>
  ),
}) => {
  return (
    <Container id="top">
      <TwoColumn>
        <LeftColumn>
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <ActionsContainer>
              <Actions to="/contact/">資料請求、お問い合わせはこちら</Actions>
              <Actions to="/consultcloud/">無料相談会実施中！</Actions>
            </ActionsContainer>
          </Content>
        </LeftColumn>
        <RightColumn>
          <StaticImage
            src="../images/hero-cloud.svg"
            placeholder="TRACED_SVG"
            aspectRatio={6 / 5}
            alt="Cloud"
          />
        </RightColumn>
      </TwoColumn>
    </Container>
  )
}
