import React from "react"
import AnimationRevealPage from "../components/helpers/AnimationRevealPage.jsx"
import ScrollToTop from "../components/helpers/ScrollToTop.jsx"
import Hero from "../components/HeroCloud.jsx"
import HeaderCloud from "../components/HeaderCloud.jsx"
import NewsCloud from "../components/NewsCloud.jsx"
import UseCase from "../components/UseCase.jsx"
import MainFeature1 from "../components/MainFeatureCloud1.jsx"
import MainFeature2 from "../components/MainFeatureCloud2.jsx"
import MainFeature3 from "../components/MainFeatureCloud3.jsx"
import Contact from "../components/Contact.jsx"
import Footer from "../components/Footer.jsx"
import FAQ from "../components/FAQ.jsx"
import SEO from "../components/misc/SEO.jsx"

export default () => (
  <AnimationRevealPage disabled>
    <SEO title="AWS導入とコスト削減をもっと手軽に！｜サクッとクラウド" />
    <HeaderCloud />
    <Hero />
    <NewsCloud />
    <ScrollToTop />
    <MainFeature1 />
    <MainFeature2 />
    <MainFeature3 />
    <UseCase service="cloud" />
    <Contact />
    <FAQ service="cloud" />
    <Footer />
  </AnimationRevealPage>
)
