import React from "react"
import tw, { styled } from "twin.macro"
import { SectionHeading } from "./misc/Headings.jsx"
import StatsIllustrationSrc from "../images/mainfeaturecloud1.svg"

import { Container as ContainerBase } from "./misc/Layouts.jsx"
import { Link } from "gatsby"

const Container = tw(ContainerBase)`relative -mx-8 -mb-8`
const HeaderContainer = tw.div`w-full flex flex-col items-center my-8`
const Title = tw(SectionHeading)`w-full  pt-12`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pb-12`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(
  Column
)`md:w-5/12 mt-6 flex-shrink-0 h-80 md:h-auto relative`
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
])

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
])
const TextContent = tw.div`lg:py-8 text-center md:text-left`

const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`
const Description = tw.p`mt-4 mb-6 mx-8 lg:mx-0 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const ActionsContainer = tw.div`flex flex-col lg:flex-row `
const Actions = tw(
  Link
)`text-center text-base md:text-lg rounded-full px-8 py-3 mx-2 mb-8  font-bold  bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`

export default ({
  title = <>「サクッとクラウド」の特徴</>,
  heading = (
    <>
      「サクッと」最短1週間で
      <br />
      クラウド基盤のアーキテクチャを提案します。
    </>
  ),
  description = (
    <>
      システムの全体像が具体的に決まっていれば、最短1週間でアーキテクチャ提案が可能です。
      <br />
      もちろん、要件が固まっていない状態からでもご相談ください。
    </>
  ),
  imageSrc = StatsIllustrationSrc,
  textOnLeft = false,
}) => {
  return (
    <Container id="feature">
      <HeaderContainer>
        <Title>{title}</Title>
      </HeaderContainer>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <ActionsContainer>
              <Actions to="/contact/">資料請求、お問い合わせはこちら</Actions>
              <Actions to="/consultcloud/">無料相談会実施中！</Actions>
            </ActionsContainer>
          </TextContent>
        </TextColumn>
        <ImageColumn>
          <Image imageSrc={imageSrc} />
        </ImageColumn>
      </TwoColumn>
    </Container>
  )
}
